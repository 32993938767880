import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "../api/axios";
import { Loader, TransactionInfoTable } from "../components";

const TransactionInfo = () => {
    const {id} = useParams();
    const getTransaction = async () => {
      const response = await api.get(`/transactions/${id}`);
      return response.data;
  }
    const {
      isLoading,
      isError,
      error,
      data,
      isFetching,
      isPreviousData } = useQuery(['getTransaction'], getTransaction);
      if(isLoading){
        return <Loader />
      }
    return (
        <section className="transaction-info">
            <TransactionInfoTable data={data} />
        </section>
    );
}

export default TransactionInfo;
