import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { format } from 'date-fns'
import IconButton from "@mui/material/IconButton";
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from "react-router-dom";
import khartoum from '../../../assets/images/banks_logos/khartoum.png'
import islamic from '../../../assets/images/banks_logos/islamic.png'
import onb from '../../../assets/images/banks_logos/onb.png'
import faisal from '../../../assets/images/banks_logos/faisal.png'
import noDataSVG from '../../../assets/SVGs/noData.svg'

import {
    Avatar,
    ListItem,
    ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { grey } from '@mui/material/colors';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const CustomersTableBody = (
    { usersData,
        selected,
        setSelected,
        order,
        orderBy,
        page,
        rowsPerPage,
        dense }) => {

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const formatDate = (jsonDate) => {
        const date = new Date(jsonDate);
        const formattedDate = format(date, 'dd/MMM/yyyy');
        return formattedDate
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - usersData?.total_items)
            : 0;
    //only for testing status column design
    function fiftyFifty() {
        return Math.random() < 0.5;
    }
    function randomDateAndMoney() {
        // Generate a random date between 1/1/1970 and today
        const startDate = new Date(2023, 0, 1); // 1/1/1970
        const today = new Date();
        const randomDate = new Date(startDate.getTime() + Math.random() * (today.getTime() - startDate.getTime()));

        const randomMoney = Math.floor(Math.random() * 98000) + 2000;
        const moneyString = randomMoney.toLocaleString('en-US');
        const dateString = `${randomDate.getMonth() + 1}/${randomDate.getDate()}/${randomDate.getFullYear()}`;

        // Return an object containing the date and money amount
        return { date: formatDate(dateString), money: moneyString };
    }
    const logos = [khartoum, islamic, onb, faisal];
    function getRandomItem(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    return (
        <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 data.slice().sort(getComparator(order, orderBy)) */}
            {usersData
                ? (stableSort(usersData.data, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const isItemSelected = isSelected(row.ID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const logo = getRandomItem(logos)
                        const IsActive = fiftyFifty()
                        const DateAndMoney = randomDateAndMoney()
                        return (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.ID)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.ID}
                                selected={isItemSelected}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                >
                                    <ListItem disablePadding>
                                        <Avatar alt="user" src={row?.url} />
                                        <ListItemText
                                            primary={(row.username || row.mobile) || 'N/A'}

                                            sx={{
                                                m: 1,
                                                color: !(row.username || row.mobile) && grey[400]
                                            }}
                                        />
                                    </ListItem>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: !(row.username || row.mobile) && grey[400]
                                    }}
                                >{row.mobile || 'N/A'}</TableCell>
                                <TableCell>
                                    <div className={`flex gap-1 w-20 justify-center py-1 px-2 
                                ${IsActive ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600'} 
                                 rounded-lg `}>
                                        <span>
                                            <CircleIcon sx={{ fontSize: 10 }} />
                                        </span>
                                        <p>
                                            {IsActive ? "Active" : "Inactive"}
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className=" text-[0.9em] text-gray-500">{DateAndMoney.date}</p>
                                    <p className=" font-bold text-gray-600 mt-0.5"><span className='font-bold text-gray-400' >SDG</span> {DateAndMoney.money}</p>
                                </TableCell>
                                <TableCell>
                                    <div>
                                        <img src={logo} className=' w-24 opacity-90 rounded-lg' alt="" />
                                    </div>
                                </TableCell>
                                <TableCell>{formatDate(row.CreatedAt)}</TableCell>
                                <TableCell sx={{ display: 'none' }}>{row.ID}</TableCell>
                                <TableCell>
                                    <Link className="edit-btn" to={`/users/${row.ID}`}>
                                        <IconButton >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    }))
                : (
                    <TableRow
                        style={{
                            height: (dense ? 33 : 53) * emptyRows,
                        }}
                    >
                        <TableCell colSpan={100} >
                            <div className="mx-auto flex flex-col w-fit my-6 ">
                                <img src={noDataSVG} className='w-40 opacity-50' alt="No data illustration" />
                                <p className="mt-3 relative left-9 text-xl font-medium text-gray-500">No data found.</p>
                            </div>
                        </TableCell>

                    </TableRow>
                )
            }
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: (dense ? 33 : 53) * emptyRows,
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
            )}

        </TableBody>
    );
}

export default CustomersTableBody;