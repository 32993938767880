import { useState } from "react";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import FiltersList from "../../filters/FiltersList";
import { SelectFilter } from "../..";
import SingleDateFilter from "../../filters/SingleDateFilter";
import InputFilter from "../../filters/InputFilter";
import DateRangeFilter from "../../filters/DateRangeFilter";

const FiltersSection = ({ children, setTransactionType, setExpDate, setAmount, setFromDate, setToDate }) => {

    const [isFiltersListOpen, setIsFiltersListOpen] = useState(false);
    const [enabledFilters, setEnabledFilters] = useState([]);
    const [filtersValues, setFiltersValues] = useState({});

    const toggleFiltersList = () => {
        setIsFiltersListOpen(!isFiltersListOpen); // toggle the state between open and closed
    };
    const handleClickAway = () => {
        setIsFiltersListOpen(false);
    };

    const getFilterOptions = (filterName) => {
        switch (filterName) {
            case 'transactionType':
                return ['Zain TopUp', 'Electricity', 'E15 payment']
            default:
                break;
        }
    }
    const handleClear = (filterName, resetFilter = null) => {
        const index = enabledFilters.indexOf(filterName);
        const newList = [...enabledFilters]
        if (index > -1) {
            newList.splice(index, 1);
        }

        if (resetFilter) {
            if (Array.isArray(resetFilter)) {
                resetFilter.forEach(filter => {
                    filter('')
                });
            }
            else {
                resetFilter('')
            }
        }

        setEnabledFilters(newList)
    };

    const filtersList = ['Amount', 'Transaction date', 'Expiration date', 'Transaction type'];

    return (
        <div className="w-full">
            <div className="flex gap-1 relative  w-fit">
                {children}
                <Button onClick={toggleFiltersList} className='whitespace-nowrap' variant="outlined" startIcon={<AddIcon />}
                    sx={{ textTransform: 'none' }} >Add filter</Button>
                
                    {isFiltersListOpen && <FiltersList
                        enabledFilters={enabledFilters}
                        setEnabledFilters={setEnabledFilters}
                        filters={filtersList}
                        handleClickAway={handleClickAway} />}
            
            </div>

            {/*rendering filters based on checked options from filters list */}
            <div className={`flex flex-wrap gap-3 ${enabledFilters.length > 0 && 'mt-3'} `}>
            {enabledFilters.includes('Transaction date') && <DateRangeFilter
                    startFilter={setFromDate}
                    endFilter={setToDate}
                    startLabel={'Transactions from'}
                    endLabel={'Transactions to'}
                    handleClear={() => handleClear('Transaction date', [setFromDate , setToDate])}
                />
                }
                {enabledFilters.includes('Expiration date') && <SingleDateFilter
                    setFilter={setExpDate}
                    label={'Expiration date'}
                    handleClear={() => handleClear('Expiration date', setExpDate)}
                />

                }
                {enabledFilters.includes('Transaction type') && <SelectFilter
                    options={getFilterOptions('transactionType')}
                    filtersValues={filtersValues}
                    setFiltersValues={setTransactionType}
                    label={'Transaction type'}
                    handleClear={() => handleClear('Transaction type', setTransactionType)}
                />
                }
                {enabledFilters.includes('Amount') && <InputFilter
                    setFilter={setAmount}
                    label={'Amount'}
                    handleClear={() => handleClear('Amount', setAmount)}
                />
                }
                
            </div>
        </div>
    );
}

export default FiltersSection;