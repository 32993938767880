import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ClearIcon from "@mui/icons-material/Clear";

export default function SelectFilter({ filterValue, setFiltereValue, label, options, handleClear = null }) {

  const handleChange = (event) => {
    setFiltereValue(event.target.value);
  };

  return (
    <Box className='flex gap-0 items-center mb-1 min-w-[47.5%] md:min-w-[180px]'>
      <FormControl fullWidth size='small'>
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          label={label}
          onChange={handleChange}
        >
          {options && (options.map((value, index) => {
            return (
              <MenuItem key={value} value={value}>{value}</MenuItem>
            );
          }))}
        </Select>
      </FormControl>
      {handleClear && <Box className='relative flex h-5 left-0.5 items-center rounded-full
       bg-gray-200 group cursor-pointer
      ' onClick={handleClear}>
        <ClearIcon fontSize='small' className='text-gray-400 group-hover:text-gray-500' />
      </Box>}
    </Box>
  );
}