import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Box from '@mui/material/Box';
import ClearIcon from "@mui/icons-material/Clear";

const SingleDateFilter = ({ setFilter, label, handleClear }) => {
    const [value, setValue] = useState(null);
    return (
        <Box className='flex gap-0 items-center mb-1 w-[47.5%] md:w-[180px] '>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DatePicker
                    label={label}
                    inputFormat="MM/YYYY"
                    value={value}
                    views={["year", "month"]}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    onAccept={() => setFilter(moment(value?.$d).format('YYMM'))}
                    renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                />

            </LocalizationProvider>
            {handleClear && <Box className='relative flex h-5 left-0.5 items-center rounded-full
                 bg-gray-200 group cursor-pointer
                ' onClick={handleClear}>
                <ClearIcon fontSize='small' className='text-gray-400 group-hover:text-gray-500' />
            </Box>}
        </Box>

    );
}

export default SingleDateFilter;