import axios from "axios";
let config = require('../config.json');

export const api =  axios.create({
    baseURL: config.prod_mode? config.prod_url : config.local_url,
});

export const getUsers = async () => {
    const response = await api.get('/users');
    return response.data;
}

export const getTrans = async () => {
    const response = await api.get('/transactions');
    return response.data;
}
