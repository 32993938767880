import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Box from '@mui/material/Box';
import ClearIcon from "@mui/icons-material/Clear";

const DateRangeFilter = ({ startLabel, endLabel, handleClear, startFilter, endFilter }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    return (
        <Box className='flex gap-0 items-center mb-1 '>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className=' w-[44%] md:w-[180px]' >
                    <DatePicker
                        label={startLabel}
                        value={startDate}
                        onChange={(startVal) => {
                            setStartDate(startVal);
                            startFilter(moment(startVal?.$d).format('YYYY-MM-DD'));
                        }}
                        renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                    />
                </Box>
            </LocalizationProvider>
            <span className="mx-0.5 h-1 w-4 bg-gray-400 rounded-full"></span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className=' w-[44%] md:w-[180px]' >
                    <DatePicker
                        label={endLabel}
                        value={endDate}
                        onChange={(endVal) => {
                            setEndDate(endVal);
                            endFilter(moment(endVal?.$d).format('YYYY-MM-DD'));
                        }}
                        renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                    />
                </Box>
            </LocalizationProvider>
            {handleClear && <Box className='relative flex h-5 left-0.5 items-center rounded-full
                 bg-gray-200 group cursor-pointer
                ' onClick={handleClear}>
                <ClearIcon fontSize='small' className='text-gray-400 group-hover:text-gray-500' />
            </Box>}
        </Box>

    );
}

export default DateRangeFilter;