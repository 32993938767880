import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authContext } from "../context/AuthContext";
import GoogleIcon from "../assets/images/google-icon.svg";
let config = require('../config.json');
// import {makeStyles} from "@mui/styles";
// const useStyles = makeStyles({
//     btn: {
//         backgroundColor: '#333'
//     }
//   })
const Login = () => {
  const { isAuthenticated } = useContext(authContext);
  const Navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //   const classes = useStyles()
  const HandleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if(isAuthenticated === true){
      Navigate("/");}
    //   let data = {username, password}

    //   axios.post('/', data)
    //    .then(res =>{
    //     setIsLoading(false);
    //     console.log(res.data);
    //    }).catch(err =>{
    //     setErr(err.response.data)
    //    })
  };

  return (
    <section className="login flex items-center">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="login-container"
        >
          <Box
            width="50%"
            display="flex"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <img
                src={require("../assets/images/logo.png")}
                width="80"
                className="mx-auto"
                alt="tutipay logo"
              />
              <Typography
                variant="h4"
                fontWeight="bold"
                className="main-color"
                component="h1"
              >
                Tutipay
              </Typography>
              <Typography variant="h6" mb={3}>
                Web Portal Dashboard
              </Typography>
              {isLoading && (
                //  Spinner
                <div></div>
              )}
              {err && <div className="alert alert-danger">{err.msg.msg} </div>}
              <form onSubmit={(e) => HandleSubmit(e)}>
                <div className="mb-2">
                  <TextField
                    id="outlined-basic"
                    className="din"
                    style={{ width: "300px" }}
                    label="Email or username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  {/* <TextField
                    id="outlined-basic"
                    className="din"
                    style={{ width: "300px" }}
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  /> */}
                  <FormControl  style={{ width: "300px" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=> setShowPassword(!showPassword)}
                            // onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
                <a href={config.prod_mode? config.prod_url + "/login" : config.local_url + "/login"}>
                  <div className="flex items-center rounded bg-slate-50 justify-between mb-2">
                  <img className="w-35 mx-2" src={GoogleIcon} width={35} alt="" />
                  <span className="bg-slate-100 text-slate-500 font-medium rounded-r whitespace-nowrap	 px-14 py-3">Sign in with Google</span>
                  </div>
                  </a>
                <Typography mb={1}>
                  forget your password? <a href="#" className="main-color">Reset password</a>
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  onClick={(e) => HandleSubmit(e)}
                  style={{ backgroundColor: "#F9550E" }}
                >
                  Login
                </Button>
              </form>
            </Box>
          </Box>
          <Box width="50%" className="login-wallpaper"></Box>
        </Stack>
      </Container>
    </section>
  );
};

export default Login;
