import { Avatar, Box, Divider, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "../api/axios";
import { Apexchart, Barchart, UserTable, UsersTable, Loader } from "../components";

const ClientsProfile = () => {
  const { id } = useParams();
  const getUser = async () => {
    const response = await api.get(`/users/${id}`);
    return response.data;
  }
  // const {
  //   isLoading,
  //   isError,
  //   error,
  //   data,
  //   isFetching,
  //   isPreviousData } = useQuery(['getUsers'], getUser);
  //   if(isLoading){
  //     return <Loader />
  //   }

  const data = {
    user: {
      name: 'Ahmed',
      PAN: '9244',
      ID: '79',
      mobile: '0922092381',
      responseStatus: 'nice'
    }
  }
  return (
    <section className="clients-profile">
      {/* <Container> */}
      <Box
        sx={{
          textAlign: "center",
          mb: 2,
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={require("../assets/images/avatar.jpg")}
          sx={{ width: 120, height: 120, margin: "auto", mb: 1 }}
        />
        <Typography variant="h5" component="h1">
          Ahmed Mohamed Ali
        </Typography>
      </Box>

      {/* <Stack direction="row" alignItems="center" justifyContent="center" sx={{mb:4}}>
          <Box width="50%">
            <Barchart />
          </Box>
          <Box width="50%">
            <Apexchart />
          </Box>
        </Stack> */}
      {/* </Container> */}
      {/* <div style={{padding: '20px 0'}}>
       <Divider />
       </div> */}
      {data ?
        <UserTable data={data.user} /> : 'error'
      }

    </section>
  );
};

export default ClientsProfile;
