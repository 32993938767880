import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { ClickAwayListener } from "@mui/material";

const FiltersList = ({ enabledFilters, setEnabledFilters, handleClickAway, filters }) => {

    const handleToggle = (value) => () => {
        const currentIndex = enabledFilters.indexOf(value);
        const newChecked = [...enabledFilters];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setEnabledFilters(newChecked);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className="absolute top-11 right-0 shadow-md shadow-gray-300 z-10">
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {filters.map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${index}`;
                        return (
                            <ListItem
                                onClick={handleToggle(value)}
                                key={index}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(value)}
                                        checked={enabledFilters.indexOf(value) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText id={labelId} primary={` ${value}`} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </ClickAwayListener>

    );
}

export default FiltersList;