import StarIcon from '@mui/icons-material/Star';

const TransactionInfoTable = ({ data }) => {
  console.log(data);
  return (
    <div className="w-full">
      <table className="table-auto shadow-md bg-white w-3/4 lg:w-1/2 mx-auto border-collapse border border-slate-200 ">
        <tbody>
          <tr>
            <td className="font-medium text-slate-600 text-sm px-4 py-4 border-y border-slate-200 w-80">
              Name
            </td>
            <td className="px-4 py-4 border-y font-medium border-slate-200 text-sm">
              {data?.name ?? "/"}
            </td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-600 text-sm px-4 py-4 border-y border-slate-200 w-80">
              PAN
            </td>
            <td className="px-4 py-4 border-y border-slate-200 text-sm">
              <div className='flex flex-col gap-2 '>
                <p className='font-medium'> {data?.PAN ?? '/'} <span className='text-orange-400 relative bottom-0.5'><StarIcon sx={{width:'17px'}}/></span></p>
                <p> {data?.PAN ?? '/'}</p>
                <p> {data?.PAN ?? '/'}</p>
              </div>

            </td>
          </tr>
          <tr>
            <td className="font-medium text-slate-600 text-sm px-4 py-4 border-y border-slate-200 w-80">
              ID
            </td>
            <td className="px-4 py-4 border-y border-slate-200 text-sm font-medium">{data?.ID ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-600 text-sm px-4 py-4 border-y border-slate-200 w-80">
              Mobile
            </td>
            <td className="px-4 py-4 border-y border-slate-200 text-sm font-medium">{data?.mobile ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-600 text-sm px-4 py-4 border-y border-slate-200 w-80">
              Status
            </td>
            <td className="px-4 py-4 border-y border-slate-200 text-sm font-medium">{data?.responseStatus ?? '/'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionInfoTable;
