import { useState, useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useLocation } from "react-router-dom";
import { Header, Loader, ProtectedRoute, Sidebar } from "./components";
import { Agents, ClientsProfile, Home, Login, Profile, Reports, Transactions, Customers } from "./pages";
import { authContext } from "./context/AuthContext";
import TransactionInfo from "./pages/TransactionInfo";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

function App() {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useContext(authContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#f9550e',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {pathname !== "/login" && <Header toggleFunc={handleDrawerToggle} />}
        <Stack direction="row" spacing={1}>
          {pathname !== "/login" && (
            <Sidebar open={mobileOpen} close={handleDrawerToggle} />
          )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<Customers />} />
            <Route path="/users/:id" element={<ClientsProfile />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/transactions/:id" element={<TransactionInfo />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
          {/* {pathname !== "/login" && (
            <Livechat/>
          )} */}
        </Stack>

      </div>
      <ToastContainer />

    </ThemeProvider>
  );
}

export default App;
