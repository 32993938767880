import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import FiltersList from "../../filters/FiltersList";
import { SelectFilter } from "../..";


const FiltersSection = ({ children }) => {
    const [isFiltersListOpen, setIsFiltersListOpen] = useState(false);
    const [enabledFilters, setEnabledFilters] = useState([]);
    const [filtersValues, setFiltersValues] = useState({});

    const toggleFiltersList = () => {
        setIsFiltersListOpen(!isFiltersListOpen); // toggle the state between open and closed
    };
    const handleClickAway = () => {
        setIsFiltersListOpen(false);
    };

    const getFilterOptions = (filterName) => {
        switch (filterName) {
            case 'provider':
                return ['Khartoum Bank', 'Al-Nail Bank', 'Omdurman Bank', 'Islamic Bank']
            case 'platform':
                return ['POS', 'Mobile app', 'Web portal']
            case 'status':
                return ['Active', 'Inactive']
            default:
                break;
        }
    }
    const handleClear = (filterName) => {
        const index = enabledFilters.indexOf(filterName);
        const newList = [...enabledFilters]
        if (index > -1) {
            newList.splice(index, 1);
        }
        setEnabledFilters(newList)
    };

    const filtersList = ['Card provider', 'Platform', 'Status'];

    return (
        <div className="w-full">
            <div className="flex gap-1 relative w-fit">
                {children}
                <Button onClick={toggleFiltersList} className=' whitespace-nowrap' variant="outlined" startIcon={<AddIcon />}
                    sx={{ textTransform: 'none' }} >Add filter</Button>

                {isFiltersListOpen && <FiltersList
                    enabledFilters={enabledFilters}
                    setEnabledFilters={setEnabledFilters}
                    filters={filtersList}
                    handleClickAway={handleClickAway} />}

            </div>

            {/*rendering filters based on checked options from filters list */}
            <div className={`flex flex-wrap gap-3 ${enabledFilters.length > 0 && 'mt-3'} `}>
                {enabledFilters.includes('Card provider') && <SelectFilter
                    options={getFilterOptions('provider')}
                    filtersValues={filtersValues}
                    setFiltersValues={setFiltersValues}
                    label={'Card provider'}
                    handleClear={() => handleClear('Card provider')}
                />}

                {enabledFilters.includes('Platform') && <SelectFilter
                    options={getFilterOptions('platform')}
                    filtersValues={filtersValues}
                    setFiltersValues={setFiltersValues}
                    label={'Platform'}
                    handleClear={() => handleClear('Platform')}
                />
                }

                {enabledFilters.includes('Status') && <SelectFilter
                    options={getFilterOptions('status')}
                    filtersValues={filtersValues}
                    setFiltersValues={setFiltersValues}
                    label={'Status'}
                    handleClear={() => handleClear('Status')}
                />
                }
            </div>
        </div>
    );
}

export default FiltersSection;