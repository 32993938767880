import { createContext, useEffect, useState } from "react";
import { api } from "../api/axios";

export const authContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const getIsAuthenticated = async () => {
      const response = await api.get("/isauthenticated");
      return response?.data?.authenticated;
  };
console.log(localStorage.getItem('isAuth'))

useEffect(() => {
    getIsAuthenticated().then(response=> 
      response && setIsAuthenticated(response?.data?.authenticated)
    ).catch(
      err=> err && setIsAuthenticated(err?.response?.data?.authenticated)
    );
  }, []);


  return (
    <authContext.Provider value={{ isAuthenticated }}>
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
