import { FormControl, TextField } from "@mui/material";
import { useState } from "react";
import { Loader, SelectFilter, CustomersTable } from "../components";

const Customers = () => {
  const [open, setOpen] = useState(false);
  const [Pin, setPin] = useState('');
  const [phone, setPhone] = useState('');

  const search = () => {

  }
  
  return (
    <section className="users-page h-full flex flex-col gap-5">
      <div className="w-fit">
        <h1 className="relative font-bold text-2xl text-gray-800">Customers
        <span className="absolute bottom-0 left-0 h-[3px] rounded-sm w-1/2 bg-orange-500"></span>
        </h1>
      </div>
      <div className="table-container">
        <CustomersTable />
      </div>
      {/* <hr className="my-5" /> */}
      {/* <div>
        <h4 className="text-xl mb-3">
          Reset Users Password:
        </h4>
        <form onSubmit={e => { e.preventDefault(); search() }}>
          <div className="flex mb-2">
            <FormControl>
              <TextField
                variant="outlined"
                placeholder="enter pin number"
                type="tel"
                label="Pin"
                onChange={(e) => setPin(e.target.value)}
                inputProps={{ maxLength: 16 }}
              />
            </FormControl>
            <div className="mx-2">
              <FormControl>
                <TextField
                  variant="outlined"
                  placeholder="enter phone number"
                  type="tel"
                  label="Phone number"
                  onChange={(e) => setPhone(e.target.value)}
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </div>
            <button
              className="mx-2 bg-color w-40 text-white"
              type="submit"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div> */}

      {/* {open === false ? (
        <div>
          <p className="py-5 text-lg font-light">
            *Provide the info to find a spcific transaction.
          </p>
        </div>
      ) : isLoading? <Loader /> : <p>Hi</p> 
} */}
    </section>
  );
}

export default Customers;