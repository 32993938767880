import { useEffect } from "react";
import { api } from "../api/axios";
import { SingleTransactionTable, TransactionsTable } from "../components";

const Transactions = () => {
  return (
    <section className="transactions h-full flex flex-col gap-5">
      <div className="w-fit">
        <h1 className="relative font-bold text-2xl text-gray-800">Transactions
        <span className="absolute bottom-0 left-0 h-[3px] rounded-sm w-1/2 bg-orange-500"></span>
        </h1>
      </div>
      <div className="table-container">
        <TransactionsTable />
      </div>
      {/* <hr className="my-5" />
      <h4 className="text-xl mb-3">Search for a transaction:</h4>
      <div className="table-container">
        <SingleTransactionTable />
      </div> */}
    </section>
  );
};

export default Transactions;
