import { Box, Toolbar, Typography } from "@mui/material";
import { Apexchart } from "../components";
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import { Slide } from 'react-toastify';

const Home = () => {

  const [isUserLogged, setIsUserLogged] = useState(false);
  
  const greet = (person) => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return `🌅 Good morning, ${person}!`;
    } else if (currentHour < 18) {
      return `🌤️ Good afternoon, ${person}!`;
    } else {
      return `🌙 Good evening, ${person}!`;
    }
  }

  const displayGreeting = (agentName) => {
    const customId = "custom-id-yes"
    setTimeout(() => {
      toast(greet(agentName), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
        toastId: customId,
      });
    }, 1000);

  }

  useEffect(() => {
    displayGreeting('Ahmed')
  }, [isUserLogged])

  return (
    <section>
      <Apexchart />
    </section>
  );
}

export default Home;