const TransactionInfoTable = ({ data }) => {
  console.log(data);
  return (
    <div>
      <table className="table-auto bg-white w-full border-collapse border border-slate-200">
        <tbody>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
              Name
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">
              {data?.name ?? "/"}
            </td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
              Pan
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.PAN ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
              ID
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.ID ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
              Status
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.responseStatus ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
              UUID
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.UUID ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            AccountCurrency
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.accountCurrency ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            AcqTranFee
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.acqTranFee ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            Authentication Type
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.authenticationType ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            Expiration Date
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.expDate ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            From Account
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.fromAccount ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            From Account Type
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.fromAccountType ?? '/'}</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            IssuerTranFee
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.issuerTranFee ?? '/'}</td>
          </tr>
          <tr>
            <td className="font-medium text-slate-700 text-sm px-4 py-4 border border-slate-200 w-80">
            PayeeId
            </td>
            <td className="px-4 py-4 border border-slate-200 text-sm">{data?.payeeId ?? '/'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionInfoTable;
