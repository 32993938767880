import SearchIcon from '@mui/icons-material/Search';
import {
    FormControl,
    InputAdornment,
    OutlinedInput, 
    IconButton
  } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const SearchBar = ({setSearchTxt, searchTxt, placeholder}) => {
    return ( 
        <div>
        <FormControl className='w-full sm:w-[300px]' size="small" variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            onChange={(e)=> setSearchTxt(e.target.value)}
            value={searchTxt}
            startAdornment={
              <InputAdornment position="start">
                  <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              searchTxt ? (
                <InputAdornment position="end">
                  <IconButton onClick={()=>{setSearchTxt('')}}>
                    <ClearIcon fontSize='small' />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            placeholder={placeholder}
          />
        </FormControl>
      </div>
     );
}
 
export default SearchBar;