import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import {
    Avatar,
    ListItem,
    ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import noDataSVG from '../../../assets/SVGs/noData.svg'
import CircleIcon from '@mui/icons-material/Circle';
import { format } from 'date-fns'
import { grey } from '@mui/material/colors';

const TransactionsTableBody = ({
    transactionsData,
    selected,
    setSelected,
    order,
    orderBy,
    page,
    rowsPerPage,
    dense }) => {

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    // Avoid a layout jump when reaching the last page with empty data.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - transactionsData?.total_items)
            : 0;


    //only for testing status column design
    function fiftyFifty() {
        return Math.random() < 0.5;
    }

    const formatDate = (jsonDate) => {
        const date = new Date(jsonDate);
        const formattedDate = format(date, 'dd/MMM/yyyy');
        const formattedTime = format(date, 'hh:mm: a');
        return { formattedDate, formattedTime }
    }

    return (<TableBody>

        {transactionsData ? (stableSort(
            transactionsData.data,
            getComparator(order, orderBy)
        )
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                const isItemSelected = isSelected(row.ID);
                const labelId = `enhanced-table-checkbox-${index}`;
                const isSuccess = fiftyFifty()
                const formatedDateTime = formatDate(row.CreatedAt)
                return (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.ID)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.ID}
                        selected={isItemSelected}
                    >
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    "aria-labelledby": labelId,
                                }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            <ListItem disablePadding>
                                <Avatar alt="user" src={row?.url} />
                                <ListItemText
                                    style={{ margin: "10px",
                                    color: !row.name && grey[400] }}
                                    primary={row.name ?? 'N/A'}
                                />
                            </ListItem>
                        </TableCell>
                        <TableCell
                            sx={{
                                color: !row.PAN && grey[400]
                            }}
                        >
                            {row.PAN ?? 'N/A'}
                        </TableCell>
                        <TableCell> <p className={`${!row.tranAmount?'text-gray-400':'text-gray-700 font-medium '}`}>{row.tranAmount ?? 'N/A'}</p></TableCell>
                        <TableCell><p className={`${row.tranCurrency?'font-bold text-md text-gray-500':'font-bold text-md text-gray-500'}`}>{row.tranCurrency ?? 'SDG'}</p> </TableCell>
                        <TableCell>
                            <div className={`flex gap-1 w-fit justify-center py-1 px-2 
                                ${isSuccess ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-500'} 
                                 rounded-lg `}>
                                <span className="relative bottom-[1px]" >
                                    <CircleIcon sx={{ fontSize: 10 }} />
                                </span>
                                <p>
                                    {isSuccess ? "Completed" : "Failed"}
                                </p>
                            </div>
                        </TableCell>
                        <TableCell>
                            <p>{formatedDateTime.formattedDate}</p>
                            <p className="text-gray-500 mt-0.5 ml-0.5">at {formatedDateTime.formattedTime}</p>
                        </TableCell>
                        <TableCell>{row.ID}</TableCell>
                        <TableCell>
                            <Link
                                className="edit-btn"
                                to={`/transactions/${row.ID}`}
                            >
                                <IconButton>
                                    <VisibilityIcon />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </TableRow>
                );
            }))
            : (
                <TableRow
                    style={{
                        height: (dense ? 33 : 53) * emptyRows,
                    }}
                >
                    <TableCell colSpan={100} >
                        <div className="mx-auto flex flex-col w-fit my-6 ">
                            <img src={noDataSVG} className='w-40 opacity-50' alt="No data illustration" />
                            <p className="mt-3 relative left-9 text-xl font-medium text-gray-500">No data found.</p>
                        </div>
                    </TableCell>
                </TableRow>
            )}
        {emptyRows > 0 && (
            <TableRow
                style={{
                    height: (dense ? 33 : 53) * emptyRows,
                }}
            >
                <TableCell colSpan={6} />
            </TableRow>
        )}
    </TableBody>);
}

export default TransactionsTableBody;