import * as React from "react";
import {
    FormControl,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import Box from '@mui/material/Box';
import ClearIcon from "@mui/icons-material/Clear";

const InputFilter = ({ setFilter, label, handleClear }) => {
    return (

        <Box className='flex gap-0 items-center mb-1 w-[47.5%] md:w-[180px]'>
            <FormControl fullWidth size='small' variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                    {label}
                </InputLabel>
                <OutlinedInput
                    id={`${label}_field`}
                    onChange={(e) => setFilter(e.target.value)}
                    label={label}
                />
            </FormControl>
            {handleClear && <Box className='relative flex h-5 left-0.5 items-center rounded-full
                         bg-gray-200 group cursor-pointer
                 ' onClick={handleClear}>
                <ClearIcon fontSize='small' className='text-gray-400 group-hover:text-gray-500' />
            </Box>}
        </Box>

    );
}

export default InputFilter;